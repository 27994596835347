import logo from "./logo2.png";
import hero from "./iot_ugly.png";
import headingLine from "./heading-line.png"
import about1 from "./about1.png"
import quote from "./quote.png"
import bluetooth from "./bluetooth.png"
import gps from "./gps.png"
import gsm from "./gsm.png"
import lora from "./lora.png"
import sigfox from "./sigfox.png"
import wifi from "./wifi.png"
import lte from "./lte.png"
import rfid from "./rfid.png"
import zigbee from "./zigbee.png"
import React, { createRef } from "react";
import ReactModal from "react-modal";
import {
  disableBodyScroll, 
  enableBodyScroll
} from 'body-scroll-lock';
import {jQuery, $} from "jquery"
import "./Animation"
import OwlCarousel from 'react-owl-carousel';
import customInit from "./Custom"

window.$ = window.JQuery = jQuery;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
    marginTop: "50px",
    marginBottom: "40px",
    height: "70%",
    overflowY: "scroll"
  }
};

class App extends React.Component {

  constructor(props) {
    super(props);

    this.modalRef = createRef();
    this.options = {
      reserveScrollBarGap: true
    };
    
    this.state = {
      modal1IsOpen: false,
      modal2IsOpen: false
    };

    this.openModal1 = this.openModal1.bind(this)
    this.openModal2 = this.openModal2.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal1() {
    this.setState({ modal1IsOpen: true })
    disableBodyScroll(this.modalRef, this.options);
  }

  openModal2() {
    this.setState({ modal2IsOpen: true })
    disableBodyScroll(this.modalRef, this.options);
  }

  closeModal() {
    this.setState({ modal1IsOpen: false, modal2IsOpen: false })
    enableBodyScroll(this.modalRef);
  }

  componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    customInit();
  }

  render() {
    return (
      <>
        <div id="js-preloader" className="js-preloader">
          <div className="preloader-inner">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <header className="header-area header-sticky">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  <a href="/" className="logo">
                    <img style={
                      { width: "220px" }
                    }
                      src={logo}
                      alt="Logo ConsultingIOT" />
                  </a>
                  <ul className="nav">
                    <li className="scroll-to-section">
                      <a href="#top" className="active">Accueil</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#about">A Propos</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#services">Nos Services</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#portfolio">Portfolio</a>
                    </li>
                    <li>
                      <div className="gradient-button scroll-to-section">
                        <a href="#contact">
                          <i className="fa fa-phone"></i> Contactez-nous</a>
                      </div>
                    </li>
                  </ul>
                  <a className='menu-trigger'>
                    <span>Menu</span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <div className="left-content show-up header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                      <div className="row">
                        <div className="col-lg-12">
                          <h2>ConsultingIOT vous assiste dans le domaine de l'Internet Of Things (IOT)</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                      <img style={{width:"100%",height: "400px"}} src={hero}
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <OwlCarousel loop={true} autoplay={true} autoplayTimeout={2300} className="logo-carousel owl-theme">
                <div className="item active">
                  <img src={bluetooth}
                    alt="" />
                </div>
                <div className="item">
                  <img src={gps}
                    alt="" />
                </div>
                <div className="item">
                  <img src={gsm}
                    alt="" />
                </div>
                <div className="item">
                  <img src={lora}
                    alt="" />
                </div>
                <div className="item">
                  <img src={sigfox}
                    alt="" />
                </div>
                <div className="item">
                  <img src={wifi}
                    alt="" />
                </div>
                <div className="item">
                  <img src={zigbee}
                    alt="" />
                </div>
                <div className="item">
                  <img src={rfid}
                    alt="" />
                </div>
                <div className="item">
                  <img src={lte}
                    alt="" />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div id="about" className="about-us section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="section-heading">
                  <h4>
                    <em>Qui</em> Sommes-Nous ?</h4>
                  <img src={headingLine}
                    alt="" />
                  <p>
                    <strong>ConsultingIOT</strong>
                    vous assiste dans la conception, l’évolution et le développement de votre produit. Nous vous proposons un accompagnement sur mesure afin d’assurer une mise en oeuvre simple, rapide et efficace. Nos choix technologiques sont en adéquation avec vos contraintes de budget et des marchés visés. ConsultingIOT vous conseille, vous guide avec rigueur et sérieux.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-image">
                  <img src={about1}
                    alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="services" className="services section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                  <h4>Nos services pour vous <em>connectez</em> au monde !</h4>
                  <img src={headingLine}
                    alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="service-item first-service">
                  <div className="icon"></div>
                  <h4>Etude & Conception</h4>
                  <p className="item-text-front">
                    ConsultingIOT vous assiste dans la conception, l’évolution et le développement de votre produit. Nous vous proposons un accompagnement sur mesure afin d’assurer une mise en oeuvre simple, rapide et efficace. Nos choix technologiques sont en adéquation avec vos contraintes de budget et des marchés visés.
                  </p>
                  <p className="item-text-back">
                    <ul>
                      <li>Analyse des besoins clients et spécifications techniques</li>
                      <li>Etude / Pré-étude de faisabilité</li>
                      <li>Définition et rédaction des spécifications</li>
                      <li>Estimation des coûts du projet</li>
                      <li>Gestion de projet : identification de chaque phase du projet, Garantit et maîtrise des objectifs / délais fixés</li>
                      <li>Sourcing des composants low cost</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="service-item second-service">
                  <div className="icon"></div>
                  <h4>Développement de produits</h4>
                  <p className="item-text-front">ConsultingIOT se spécialise dans le développement de produits où la notion de « time to market » est primordiale. Notre métier est de développer des systèmes embarqués électroniques à partir de briques technologiques maîtrisées afin de vous proposer des temps de développement optimisés.</p>
                  <p className="item-text-back">
                    <strong>Quelques technologies maîtrisées :</strong>
                    <ul>
                      <li>Microcontrôleur : 8/16/32Bits (Cortex-M, Cortex-A…)</li>
                      <li>Modules Processeurs ARM A9: IMX6 , Multicoeurs sous OS linux , Android (via partenaire)</li>
                      <li>Analogique : Amplification, filtrage, capteurs</li>
                      <li>Communication local: Ethernet, WiFi, Bluetooth…</li>
                      <li>Communication long distance: GSM, LORA</li>
                      <li>Prise en compte des contraintes EMI/ESD</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="service-item third-service">
                  <div className="icon"></div>
                  <h4>Réalisation Circuit Imprimé</h4>
                  <p className="item-text-front">Avec plus de 25 ans d’expérience dans la conception , industrialisation , réalisation de circuits imprimés et une maîtrise des contraintes de fabrication, nous vous garantissons un travail de qualité dans le routage de vos cartes électroniques (carte « simple face » ou produits les plus complexes multicouches).</p>
                  <p className="item-text-back">
                    <strong>Exemples de réalisations maîtrisées :</strong>
                    <ul>
                      <li>Saisie de schémas électroniques (avec contraintes d’intégrité des signaux, CEM, d’isolation…)</li>
                      <li>Conception de circuits imprimés à montage en surface (CMS), PCB FR4 ,Flex , autres</li>
                      <li>Impédance contrôlée (Routage,empilage , RF)</li>
                      <li>Réalisation de circuits simple face et multicouches</li>
                      <li>Placement des composants et routage selon les standards IPC</li>
                      <li>Maîtrise et définition de l’empilage PCB</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="service-item fourth-service">
                  <div className="icon"></div>
                  <h4>Développement Logiciel</h4>
                  <p className="item-text-front">ConsultingIOT développe des logiciels embarqués, des firmwares selon vos besoins. Nous vous garantissons également la mise au point des tests fonctionnels nécessaire au bon déroulement de la production.
Nous assurons la maintenance et les mises à jours nécessaires afin de vous garantir la bonne évolution de vos produits.</p>
                  <p className="item-text-back">
                    <strong>Quelques technologies maîtrisées :</strong>
                    <ul>
                      <li>FreeRTOS</li>
                      <li>C,C++</li>
                      <li>Linux</li>
                    </ul>
                    <br /><br /><br /><br />
                  </p>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="service-item sixth-service">
                  <div className="icon"></div>
                  <h4>Production Produits Finis</h4>
                  <p className="item-text-front">ConsultingIOT apporte son expertise dans la conception et l'industrialisation de cartes électroniques afin de garantir une production maîtrisée. Nous prenons en charge l’industrialisation des prototypes jusqu’à la production série et développons les outils de tests (bancs de tests) afin de vous garantir des productions 100% testés fonctionnellement.</p>
                  <p className="item-text-back">
                    <strong>Les moyens de production de nos partenaires sont :</strong>
                    <ul>
                      <li>Prototypes rapides Low cost</li>
                      <li>Pré-série (50-150pcs)</li>
                      <li>Moyenne série (quelques milliers par lots)</li>
                      <li>Grosse série (avec planification de cadencement mensuel / trimestriel)</li>
                    </ul>
                    <br />
                  </p>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="service-item fifth-service">
                  <div className="icon"></div>
                  <h4>Moule & Injection Plastique</h4>
                  <p className="item-text-front">Capable de vous générer des modèles 3D directement depuis la CAO électronique, ConsultingIOT vous propose plusieurs formats de fichiers exportés. Ces modèles vous permettrons de les intégrer directement dans vos «designs» mécaniques et de les visualiser rapidement aux formats PDF.</p>
                  <p className="item-text-back">
                    <strong>Voici nos formats d’échange 3D :</strong>
                    <ul>
                      <li>Format STEP : Fichier universel d’échange 3D</li>
                      <li>Format PDF : Vous permet de visualiser / d’apprécier votre produit sans outils spécifique Compatible avec Acrobat Reader</li>
                    </ul>
                  </p>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="service-item seventh-service">
                  <div className="icon"></div>
                  <h4>Traitement de données</h4>
                  <p className="item-text-front">ConsultingIOT apporte une grande expertise et expérience en conception, réalisation database et remonté de données via des réseau GSM/ M2M . Nous vous proposons un accompagnement client et serveurs management avec une insertion base de donnée sur serveur Mysql.

Logistique Abonnement Opérateur :
ConsultingIOT a une expertise dans logistique des abonnes opérateurs (cartes SIM). Traçabilité des abonnements via les bases de données.</p>
                  <p className="item-text-back">
                    <strong>Quelques technologies maîtrisées :</strong>
                    <ul>
                      <li>Protocoles HTTP</li>
                      <li>Apache</li>
                      <li>MySQL</li>
                      <li>Python</li>
                      <li>Servlet</li>
                    </ul>
                    <br /><br /><br /><br /><br />
                  </p>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="service-item eigth-service">
                  <div className="icon"></div>
                  <h4>Développement d'Applications Web</h4>
                  <p className="item-text-front">
                  ConsultingIOT vous accompagne pour l’étude et la mise en œuvre de la hiérarchie des données à visualisées .Afin de rendre votre partage de données efficace, commencez par garder les données qui vont être les plus représentatives et essentielles dans votre interface web.
                  <ul>
                    <li>Tableau de bord personnalisé </li>
                    <li>Visualisation sur mesure interactive ou statique</li>
                    <li>application et outils de visualisation de données</li>
                  </ul>
                  </p>
                    <p className="item-text-back">  
                    <strong>Les domaines connexes où ConsultingIOT peut vous aider sont :</strong>
                    <ul>
                      <li>Importation, préparation et analyse d'ensembles de données</li>
                      <li>Sélection des meilleurs outils/tech-stack pour le projet</li>
                      <li>Conception structure pour représenter les informations sur les données</li>
                      <li>Construire et déployer la solution avec les outils les plus adaptés</li>
                    </ul>
                    <strong>Quelques technologies utilisés :</strong>
                    <ul>
                      <li>Symfony</li>
                      <li>Codeigniter</li>
                      <li>React JS</li>
                      <li>Angular</li>
                      <li>Node JS</li>
                    </ul>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div id="portfolio" className="the-gallery section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                  <h4>
                    <em>Portfolio</em></h4>
                  <img src={headingLine}
                    alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <OwlCarousel style={{zIndex:0}} autoWidth={true} autoplay={true} autoplayTimeout={2300} loop={true} dots={true} className="custom-carousel owl-theme">
                  <div className="item active"
                    style={
                      { backgroundImage: 'url('+process.env.PUBLIC_URL+'/assets/img/1.png)' }
                    }>
                    <div className="item-desc">
                      <h3>Adaptateur de test automatique et de programmation</h3>
                    </div>
                  </div>
                  <div className="item active"
                    style={
                      { backgroundImage: 'url('+process.env.PUBLIC_URL+'/assets/img/2.png)' }
                    }>
                    <div className="item-desc">
                      <h3>Banc de test pour câble avec mesures analogiques</h3>
                    </div>
                  </div>
                  <div className="item active"
                    style={
                      { backgroundImage: 'url('+process.env.PUBLIC_URL+'/assets/img/3.png)' }
                    }>
                    <div className="item-desc">
                      <h3>Pilotage de vannes et gestion température sur tuyau. Gestion énergie</h3>
                    </div>
                  </div>
                  <div className="item active"
                    style={
                      { backgroundImage: 'url('+process.env.PUBLIC_URL+'/assets/img/4.png)' }
                    }>
                    <div className="item-desc">
                      <h3>Conception de carte électronique  avec passage certificat CE</h3>
                    </div>
                  </div>
                  <div className="item active"
                    style={
                      { backgroundImage: 'url('+process.env.PUBLIC_URL+'/assets/img/5.png)' }
                    }>
                    <div className="item-desc">
                      <h3>Projet de pilotage de télécommande pour des votes. En  spectacle interactif</h3>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>

        <footer id="contact">
          <div className="container">
            <div className="row mb-3">
              <div className="col-lg-12 text-center">
                <p style={{ color: "white" }}> <a href="tel:+330769100504"><i style={{ marginRight: "10px", transform: "scaleX(-1)" }} className="fa fa-phone"></i> (+33) 07 69 10 05 04</a></p>
                <p style={{ color: "white" }}> <a href="mailto:jjnoury@yahoo.fr"><i style={{ marginRight: "10px" }} className="fa fa-envelope"></i> jjnoury@yahoo.fr</a></p>
                <p style={{ color: "white" }}><i style={{ marginRight: "10px" }} className="fa fa-map-pin"></i> 27 290 Illeville sur Montfort</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <div>
                  <p onClick={
                    this.openModal1
                  }
                    style={
                      {
                        cursor: "pointer",
                        color: "white"
                      }
                    }>Politique de confidentialité</p>
                  <p onClick={
                    this.openModal2
                  }
                    style={
                      {
                        cursor: "pointer",
                        color: "white"
                      }
                    }>Conditions de vente</p><br />
                  <p>
                    <strong style={
                      { color: "white" }
                    }>SIREN-SIRET : 834 114 373</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text">
                  <p>Copyright © 2022 ConsultingIOT. Tout droit réservé.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <ReactModal isOpen={
          this.state.modal1IsOpen
        }
          onRequestClose={
            this.closeModal
          }
          style={customStyles}
          contentLabel="Politique de confidentialité"
          ariaHideApp={false}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <i className="fa fa-times"
                  onClick={
                    this.closeModal
                  }
                  style={
                    {
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      fontSize: "20px",
                      cursor: "pointer"
                    }
                  }></i>
                <h3>
                  <strong>Politique de confidentialité</strong>
                </h3><br />
                <p>L’utilisation du site implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site  www.onsultingIOT.fr sont donc invités à les consulter de manière régulière.
                  Pour un meilleur confort d'utilisation, nous vous recommandons de naviguer sur ce site au moyen des versions les plus récentes des principaux navigateurs comme Internet explorer, Safari, Firefox ou Google Chrome.
                </p>

                <p>ConsultingIOT met en œuvre tous les moyens possibles pour assurer une information et une mise à jour fiables de son site internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de ConsultingIOT, et signaler toutes modifications du site qu'il jugerait utile. ConsultingIOT n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.</p>

                <p>
                  <strong>Cookies :</strong>
                  Le site www.ConsultingIOT.fr peut être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d'affichage. Pour plus d'informations à ce sujet, vous pouvez vous référer à notre politique d'utilisation des cookies.
                </p>

                <p>
                  <strong>Liens hypertextes :</strong>
                  Les liens hypertextes mis en place sur le présent site internet en direction d'autres sites et ressources ne sauraient engager la responsabilité de ConsultingIOT.
                  Les utilisateurs, les abonnés et les visiteurs du site http://www.ConsultingIOT.fr/ne peuvent mettre en place un hyperlien en direction de ce site sans l'autorisation expresse et préalable de ConsultingIOT. Toute demande d’autorisation d’hyperlien vers le site www.ConsultingIOT.fr est à adresser par mail à l’adresse contact@ConsultingIOT.fr ConsultingIOT se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa décision.
                </p>

                <p>
                  <strong>Services fournis :</strong>
                  ConsultingIOT s’efforce de fournir sur le site www.ConsultingIOT.fr des informations aussi précises que possible. Les renseignements figurant sur le site www.ConsultingIOT.com  ne sont pas exhaustifs et les photos ne sont pas contractuelles. Ces contenus sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site www.ConsultingIOT.fr sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.
                </p>

                <p>
                  <strong>Limitations contractuelles sur les données :</strong>
                  Les informations contenues sur ce site sont aussi précises que possible et le site est remis à jour le plus régulièrement possible. Il peut cependant contenir des inexactitudes ou des omissions. Si vous constatez un oubli, une erreur ou ce qui semble être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse contact@ConsultingIOT.fr.
                </p>

                <p>
                  Tout téléchargement de contenu se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, ConsultingIOT ne saurait être tenu responsable d'un quelconque dommage subi par le matériel informatique de l'utilisateur ou d'une quelconque perte de données consécutives au téléchargement. L’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant aucun virus.
                </p>

                <p>
                  <strong>Propriété intellectuelle :</strong>
                  Tout le contenu du présent site incluant, de façon non limitative, les textes, images, vidéos, animations, logos, pictogrammes, graphismes, documents téléchargeables, ainsi que leur mise en forme sont la propriété exclusive d’ConsultingIOT, à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
                  Conformément aux articles L.335-2 et suivants du Code de la propriété intellectuelle, toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite, sauf accord préalable et transmis par écrit de ConsultingIOT. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.
                </p>

                <p>
                  <strong>Déclaration à la CNIL :</strong>
                  Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel) relative à l'informatique, aux fichiers et aux libertés.
                </p>

                <p>
                  <strong>Litiges :</strong>
                  Les présentes conditions d’utilisation du site www.ConsultingIOT.fr sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.
                </p>

                <p>Politique RGPD :
                  ConsultingIOT attache une grande importance au respect des données personnelles de ses clients et à la qualité de service qu’il vous apporte au quotidien.
                  Conformément au nouveau Règlement Général sur la Protection des Données Personnelles (RGPD), nous tenions à vous communiquez les dispositions mises en œuvre pour assurer la bonne exécution de nos prestations.
                  1. Le responsable du traitement
                  Le responsable du traitement est la société ConsultingIOT,
                  située au 31 Rue Huley 27 290 Illeville sur Montfort
                  Numéro de SIRET :  834 114 373 00014
                  Téléphone :
                  Email : contact@ConsultingIOT.fr
                  Site Web : www.ConsultingIOT.fr
                  2. Les données personnelles traitées
                  Dans le cadre des activités liées à l’utilisation du site www.ConsultingIOT.fr (notamment si vous renseignez un formulaire de demande de renseignement) ou lorsque vous exploitez une solution de monitoring de vos conditions environnementales ConsultingIOT, nous sommes amenés à collecter et à traiter les données personnelles suivantes :
                  Des données d’identification (exemples : nom, prénom, adresse électronique, adresse postale, numéro de téléphone)
                  Des données de connexion (exemples : adresse IP, logs de connexion)
                  Dans l’hypothèse où vos données personnelles sont collectées par le biais d’un formulaire, vous serez informés du caractère obligatoire des informations par un astérisque (*) à côté des champs concernés. En cas d’absence d’astérisque, les informations demandées ne sont pas obligatoires.
                  Seules les données personnelles strictement nécessaires au regard des finalités décrites ci-dessous sont collectées.
                  3. Les bases légales des traitements
                  Vos données personnelles sont collectées et traitées sur les bases légales suivantes :
                  Dans le cadre de l’exécution d’un contrat ;
                  À des fins d’intérêt légitime ;
                  Sur le fondement de votre consentement ;
                  Dans le but d’assurer le respect de nos obligations légales et réglementaires.
                  4. Les finalités des traitements
                  Vos données personnelles sont traitées pour les finalités suivantes :
                  Gérer notre relation commerciale : Émission de devis, commandes, ARC, factures, réponses à vos demandes de support technique ou commercial…
                  Assurer le fonctionnement des solutions de monitoring de conditions environnementales fournies par ConsultingIOT : Envoi d’alertes par téléphone sur SMS et par email, traçabilité sur les actions réalisées par chaque utilisateur enregistré. Ces données sont conservées dans l’audit trail.
                  Réaliser des campagnes d’information et prospection commerciale
                  Améliorer et optimiser la qualité de nos services et de nos solutions
                  Réaliser des enquêtes de satisfaction sur les solutions et services proposés
                  5. Durée de conservation de vos données personnelles
                  Vos données personnelles ne sont conservées que pour la durée strictement nécessaire à l’accomplissement des finalités pour lesquelles elles sont collectées.
                  5.1 Gestion de la Relation commerciale :
                  Si vous êtes client ConsultingIOT, vos données personnelles sont conservées pour une durée de 3 ans à partir de la fin de la relation commerciale.
                  Si vous n’êtes pas client ConsultingIOT (prospect), les données personnelles que vous auriez pu nous confier sont conservées pour une durée de 12 mois à compter de votre dernière action montrant un intérêt pour les solutions et services ConsultingIOT (ex : ouverture ou réponse à un email, newsletter). Vous pouvez également vous désabonner à tout moment en cliquant sur le lien disponible sur les emails d’information et de communication que vous pourriez recevoir.
                  5.2 Données nécessaires au fonctionnement des solutions ConsultingIOT :
                  Si vous êtes utilisateur des solutions de monitoring de conditions environnementales ConsultingIOT, les données relatives à l’exploitation de la solution sont conservées selon les dispositions contractuelles définies avec le client. Ces données, intégrées à l’audit trail, sont nécessaires à assurer la traçabilité des activités monitorées par le client et à ce titre ne peuvent donc pas être supprimées sans son accord formel avant la fin de la durée contractuelle.
                  6. Les destinataires de vos données personnelles
                  Comme mentionnées précédemment vos données sont exploitées par ConsultingIOT conformément aux finalités énumérées ci-dessus. Nous utilisons également des sous-traitants à des fins techniques comme l’hébergement ou à des fins de communication. Leur accès est strictement encadré par ConsultingIOT. Ils n'ont pas la possibilité d'utiliser vos données à d'autres fins que l'exécution de leur prestation.
                  7. Les droits concernant vos données personnelles
                  Le fait de nous confier vos données ne signifie pas que vous perdez la maîtrise de celles-ci.
                  Vos disposez de plusieurs droits. Certains sont applicables à tous les traitements de données vous concernant, d’autres dépendent du type de traitement mis en œuvre et, plus précisément, du fondement juridique sur lequel il repose (contrat, obligation légale, consentement, intérêt légitime, etc .).
                  Vous trouverez ci-dessous un bref descriptif de ces droits ainsi que les modalités de leur mise en œuvre.
                  Le droit d’accès :
                  Ce droit vous permet de nous poser des questions sur la nature des traitements vous concernant (type de données, origine de la collecte, etc.). Il vous permet également de nous demander une copie de l’ensemble des informations vous concernant. Ce droit s’applique quel que soit le fondement juridique du traitement vous concernant.
                  Le Droit de rectification :
                  Le droit de rectification sert à mettre à jour vos données personnelles. Ce droit s’applique quel que soit le fondement juridique du traitement vous concernant.
                  Le droit d’opposition :
                  C’est le droit de dire « non », le droit de ne pas figurer dans un traitement de données ou de de ne plus y figurer. Ce droit s’applique lorsque le traitement repose sur « l’intérêt légitime » de ConsultingIOT, pourvu que cet intérêt ne soit pas « impérieux ».
                  Droit à l’effacement :
                  C'est le droit d'être oublié. ConsultingIOT n'a aucune raison de conserver vos données au-delà de ce qui est nécessaire. Toutes les données vous concernant sont donc automatiquement supprimées à l'expiration du délai de conservation prévu.
                  Lorsque vos données font l'objet d'un traitement reposant sur votre consentement ou sur l'intérêt légitime (non impérieux) de ConsultingIOT, vous pouvez obtenir la suppression anticipée de vos données. Comment ? Il vous suffit de nous manifester votre volonté de procéder au retrait de votre consentement ou de faire valoir votre droit d'opposition au traitement.
                  Droit à la portabilité :
                  Ce droit vous permet de demander l'obtention des données vous concernant, dès lors que celles-ci sont font l'objet d'un traitement reposant sur votre consentement ou une relation contractuelle. Nous avons l'obligation de vous transmettre les données dans un format techniquement exploitable par vous-même ou par une autre entité que ConsultingIOT.
                  Vous pouvez exercer vos droits en nous contactant à l’une des adresses suivantes :
                  -par mail : contact@ConsultingIOT.com.
                  -par voie postale : ConsultingIOT, à l’attention du Délégué à la Protection des Données,  31 Rue du Huley 27 290 Illeville sur Montfort
                  8. Contacter notre délégué à la protection des données (DPO)
                  Vous pouvez contacter notre DPO :
                  – par email à contact@ConsultingIOT.fr

                  9. Les mesures de sécurité
                  En tant que responsable de traitement, nous prenons toutes les mesures utiles pour préserver la sécurité et la confidentialité des données et notamment, empêcher qu’elles soient endommagées, ou que des tiers non autorisés y aient accès. A cette fin, nous mettons en œuvre toutes les mesures techniques et organisationnelles pour garantir un niveau de sécurité adéquat et adapté aux risques. Par ailleurs, nous nous assurons du respect par nos sous-traitants des règles en matière de protection des données personnelles.

                  Données personnelles :
                  L’utilisation du site Internet www.ConsultingIOT.fr ne requiert pas que vous communiquiez vos données personnelles.
                  Cependant, pour accéder à certains services proposés par notre site, vous pouvez être amenés à nous communiquer des données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, votre secteur d’activité ou votre numéro de téléphone. C’est le cas lorsque vous remplissez nos différents formulaires de contact ou si vous souhaitez accéder aux contenus Premium mis à disposition sur ce site. Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d’information.
                  Ces informations de contact restent strictement confidentielles, et ne sont en aucun cas transférés ou mis à disposition à des tiers.
                  Vous avez le droit d'accéder, de modifier et d'opposition aux données personnelles stockées sur vous. Vous seul pouvez exercer ces droits sur vos propres données en vous adressant à : ConsultingIOT – 31 Rue Huley 27 290 Illeville sur Montfort (France), en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.

                  Marques :
                  Les autres marques mentionnées sur le site sont la propriété de leurs propriétaires respectifs.
                  ConsultingIOT fait son possible pour fournir des informations les plus précises sur le site Web, toutefois les sujets sont soumis à changement sans avertissement préalable.
                  Les photos, images, et dessins présents sur le site ne sont pas contractuels.
                </p>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal isOpen={
          this.state.modal2IsOpen
        }
          onRequestClose={
            this.closeModal
          }
          style={customStyles}
          contentLabel="Conditions de vente"
          ariaHideApp={false}>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <i className="fa fa-times"
                  onClick={
                    this.closeModal
                  }
                  style={
                    {
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      fontSize: "20px",
                      cursor: "pointer"
                    }
                  }></i>
                <h3>
                  <strong>Conditions Générales de vente</strong>
                </h3><br />
                <p>
                  Les présentes conditions générales de vente sont présentées pour porter à la connaissance du consommateur tous les détails relatifs à son contrat avec ConsultingIOT.fr, la disponibilité des produits, le mode de livraison… En d’autres termes, ces conditions définissent clairement le processus de commande de façon à ce que l’achat se déroule dans de bonnes conditions et conformément aux législations en vigueur.
                </p>
                <br /><h4>
                  <strong>ConsultingIOT :</strong>
                </h4><br />
                <p>
                  Adresse : 31 Huley 27290 Illeville sur Monfort– FRANCE
                  Téléphone :
                  Mail : contact@consultingIOT.fr
                  Site web :  consultingIOT.fr
                  Notre offre et nos tarifs
                  ConsultingIOT couvre avec ses offres commerciales la France métropolitaine, et les pays du l’union  Europe et la Corse. Les tarifs sont clairement affichés sur le site toutes hors taxes comprises sans inclure le coût de la livraison et de la mise en service .
                  L’encaissement complet du montant d’une commande par ConsultingIOT marque le passage réel et effectif de la propriété des produits de ConsultingIOT au client.

                </p>
                <br /><h4>
                  <strong>Le processus de commande et sa validation</strong>
                </h4><br />

                <p>Au moment de la passation de la commande, vous devez indiquer vos coordonnées ( adresse, numéro de téléphone, mail). La validation de votre commande suppose votre connaissance parfaite de ces conditions générales de vente.  A ce moment, vous  pouvez imprimer votre bon de commande et attendre le traitement de votre dossier.
                  De notre part, l’équipe ConsultingIOT  vous envoie un accusé de réception de votre commande par mail qui reprend le montant total à payer et les modalités de livraison. ConsultingIOT se réserve le droit d’annuler une commande d’un client douteux avec lequel l’entreprise à un litige antérieur relatif au paiement.</p>
                <br /><h4>
                  <strong>Disponibilité, facturation et paiement des commandes</strong>
                </h4><br />
                <p>Nos offres de produits et de gestion de flotte et de  géolocalisation d’objet ,de véhicule ,engin  ne sont valables que dans la double limite de la durée de validité de l’offre concernée et des stocks disponibles. De ce fait, au moment de la passation de votre commande, toutes les indications nécessaires sur la disponibilité des produits vous sont a précisées.
                  Les prix facturés sont ceux en vigueur le jour de l’expédition de la commande. Le règlement se fait toujours au comptant en tenant compte des escomptes accordés. Il est à noter que tout type de facilité de paiement doit être validé par le siège de la société. Le retard de paiement permet de suspendre le service.
                  Il est important de savoir,à ce titre, que la loi n°92-1442 du 31 décembre 1992, stipule que tout retard de paiement donnera lieu, de plein droit et sans aucune mise en demeure préalable, à compter de la date d’échéance à l’application de pénalités fixées à un taux égal au taux REFI majoré de 10 points ainsi qu’une indemnité forfaitaire pour frais de recouvrement de 40€ par facture (article L. 441-6 du Code de commerce).
                  Le mode de paiement est identifié dans les détails de l’offre commerciale. Le paiement par chèque ou par carte bancaire ne fait pas supporter à son auteur d’autres frais supplémentaires. Le paiement en ligne est entièrement sécurisé.
                </p>

                <br /><h4>
                  <strong>Droit de rétraction et remboursement</strong>
                </h4><br />
                <p>L’article L. 121-20 du code de la consommation stipule que « le consommateur dispose d’un délai de quatorze jours francs pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités, à l’exception, le cas échéant, des frais de retour… »
                  Au retour des produits, ces derniers doivent être en état de revente ( avec accessoires, emballage…). A l’occasion de ce retour, le client prend à sa charge les frais du transport et tout risque lié à cette opération. Les frais de livraison initiaux ne sont pas remboursés.
                  Le remboursement se fait dans un délai de 15 jours à  partir de la date de sa réception par l’équipe ConsultingIOT.
                </p>

                <br /><h4>
                  <strong>Les garanties du consommateur</strong>
                </h4><br />
                <p>Les clients ConsultingIOT  bénéficient, comme tout client, de la garantie légale des vices cachés sur les produits livrés. Notre entreprise accorde une garantie de 2 ans à ses clients. Elle propose également les pièces détachées nécessaires à la réparation de ses produits durant une période de 12 mois suivant la fin de commercialisation de la version du produit concerné.
                  La propriété intellectuelle du contenu de ce site est la propriété exclusive de ConsultiongIOT
                </p>
              </div>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}

export default App;
